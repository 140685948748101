export const SET_SELECTED_ROLE_START = 'SET_SELECTED_ROLE_START';
export const SET_SELECTED_ROLE_SUCCESS = 'SET_SELECTED_ROLE_SUCCESS';
export const SET_SELECTED_ROLE_FAIL = 'SET_SELECTED_ROLE_FAIL';

export const SEND_PHONE_VERIFICATION_CODE_START =
  'SEND_PHONE_VERIFICATION_CODE_START';
export const SEND_PHONE_VERIFICATION_CODE_SUCCESS =
  'SEND_PHONE_VERIFICATION_CODE_SUCCESS';
export const SEND_PHONE_VERIFICATION_CODE_FAIL =
  'SEND_PHONE_VERIFICATION_CODE_FAIL';

export const CONFIRM_PHONE_NUMBER_START = 'CONFIRM_PHONE_NUMBER_START';
export const CONFIRM_PHONE_NUMBER_SUCCESS = 'CONFIRM_PHONE_NUMBER_SUCCESS';
export const CONFIRM_PHONE_NUMBER_FAIL = 'CONFIRM_PHONE_NUMBER_FAIL';

export const SEND_EMAIL_VERIFICATION_START = 'SEND_EMAIL_VERIFICATION_START';
export const SEND_EMAIL_VERIFICATION_SUCCESS =
  'SEND_EMAIL_VERIFICATION_SUCCESS';
export const SEND_EMAIL_VERIFICATION_FAIL = 'SEND_EMAIL_VERIFICATION_FAIL';

export const CONFIRM_EMAIL_START = 'CONFIRM_EMAIL_START';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAIL = 'CONFIRM_EMAIL_FAIL';

export const RESET_PROFILE_STORE = 'RESET_PAYMENT_STORE';
export const RESET_PHONE_VERIFICATION_STORE = 'RESET_PHONE_VERIFICATION_STORE';
export const RESET_EMAIL_VERIFICATION_STORE = 'RESET_EMAIL_VERIFICATION_STORE';

export const LOGOUT = 'LOGOUT';

export type ProfileActionTypes =
  | typeof SET_SELECTED_ROLE_START
  | typeof SET_SELECTED_ROLE_SUCCESS
  | typeof SET_SELECTED_ROLE_FAIL
  | typeof SEND_PHONE_VERIFICATION_CODE_START
  | typeof SEND_PHONE_VERIFICATION_CODE_SUCCESS
  | typeof SEND_PHONE_VERIFICATION_CODE_FAIL
  | typeof CONFIRM_PHONE_NUMBER_START
  | typeof CONFIRM_PHONE_NUMBER_SUCCESS
  | typeof CONFIRM_PHONE_NUMBER_FAIL
  | typeof SEND_EMAIL_VERIFICATION_START
  | typeof SEND_EMAIL_VERIFICATION_SUCCESS
  | typeof SEND_EMAIL_VERIFICATION_FAIL
  | typeof CONFIRM_EMAIL_START
  | typeof CONFIRM_EMAIL_SUCCESS
  | typeof CONFIRM_EMAIL_FAIL
  | typeof RESET_PROFILE_STORE
  | typeof RESET_PHONE_VERIFICATION_STORE
  | typeof RESET_EMAIL_VERIFICATION_STORE
  | typeof LOGOUT;
