import * as actionTypes from './actionTypes';
import { ProfileActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type ProfileStateType = {
  setSelectedRoleLoading: boolean;
  setSelectedRoleSuccess: boolean;
  setSelectedRoleError: HttpError;
  sendPhoneVerificationLoading: boolean;
  sendPhoneVerificationSuccess: boolean;
  sendPhoneVerificationError: HttpError;
  confirmPhoneNumberLoading: boolean;
  confirmPhoneNumberSuccess: boolean;
  confirmPhoneNumberError: HttpError;
  sendEmailVerificationLoading: boolean;
  sendEmailVerificationSuccess: boolean;
  sendEmailVerificationError: HttpError;
  confirmEmailLoading: boolean;
  confirmEmailSuccess: boolean;
  confirmEmailError: HttpError;
};

export type ProfileActionType = ProfileStateType & {
  type: ProfileActionTypes;
};

export const initialState: ProfileStateType = {
  setSelectedRoleLoading: false,
  setSelectedRoleSuccess: false,
  setSelectedRoleError: null,
  sendPhoneVerificationLoading: false,
  sendPhoneVerificationSuccess: false,
  sendPhoneVerificationError: null,
  confirmPhoneNumberLoading: false,
  confirmPhoneNumberSuccess: false,
  confirmPhoneNumberError: null,
  sendEmailVerificationLoading: false,
  sendEmailVerificationSuccess: false,
  sendEmailVerificationError: null,
  confirmEmailLoading: false,
  confirmEmailSuccess: false,
  confirmEmailError: null,
};

const setSelectedRoleStart = (state: ProfileStateType): ProfileStateType => ({
  ...state,
  setSelectedRoleLoading: true,
  setSelectedRoleSuccess: false,
});

const setSelectedRoleSuccess = (state: ProfileStateType): ProfileStateType => ({
  ...state,
  setSelectedRoleSuccess: true,
  setSelectedRoleLoading: false,
});

const setSelectedRoleFail = (
  state: ProfileStateType,
  action: ProfileActionType,
): ProfileStateType => ({
  ...state,
  setSelectedRoleError: action.setSelectedRoleError,
  setSelectedRoleLoading: false,
});

const sendPhoneVerificationCodeStart = (
  state: ProfileStateType,
): ProfileStateType => ({
  ...state,
  sendPhoneVerificationLoading: true,
  sendPhoneVerificationSuccess: false,
});

const sendPhoneVerificationCodeSuccess = (
  state: ProfileStateType,
): ProfileStateType => ({
  ...state,
  sendPhoneVerificationSuccess: true,
  sendPhoneVerificationLoading: false,
});

const sendPhoneVerificationCodeFail = (
  state: ProfileStateType,
  action: ProfileActionType,
): ProfileStateType => ({
  ...state,
  sendPhoneVerificationError: action.sendPhoneVerificationError,
  sendPhoneVerificationLoading: false,
});

const confirmPhoneNumberStart = (
  state: ProfileStateType,
): ProfileStateType => ({
  ...state,
  confirmPhoneNumberLoading: true,
  confirmPhoneNumberSuccess: false,
});

const confirmPhoneNumberSuccess = (
  state: ProfileStateType,
): ProfileStateType => ({
  ...state,
  confirmPhoneNumberSuccess: true,
  confirmPhoneNumberLoading: false,
});

const confirmPhoneNumberFail = (
  state: ProfileStateType,
  action: ProfileActionType,
): ProfileStateType => ({
  ...state,
  confirmPhoneNumberError: action.confirmPhoneNumberError,
  confirmPhoneNumberLoading: false,
});

const resetPhoneVerificationStore = (
  state: ProfileStateType,
): ProfileStateType => ({
  ...state,
  sendPhoneVerificationLoading: false,
  sendPhoneVerificationSuccess: false,
  sendPhoneVerificationError: null,
  confirmPhoneNumberLoading: false,
  confirmPhoneNumberSuccess: false,
  confirmPhoneNumberError: null,
});

const sendEmailVerificationStart = (
  state: ProfileStateType,
): ProfileStateType => ({
  ...state,
  sendEmailVerificationLoading: true,
  sendEmailVerificationSuccess: false,
});

const sendEmailVerificationSuccess = (
  state: ProfileStateType,
): ProfileStateType => ({
  ...state,
  sendEmailVerificationSuccess: true,
  sendEmailVerificationLoading: false,
});

const sendEmailVerificationFail = (
  state: ProfileStateType,
  action: ProfileActionType,
): ProfileStateType => ({
  ...state,
  sendEmailVerificationError: action.sendEmailVerificationError,
  sendEmailVerificationLoading: false,
});

const confirmEmailStart = (state: ProfileStateType): ProfileStateType => ({
  ...state,
  confirmEmailLoading: true,
  confirmEmailSuccess: false,
});

const confirmEmailSuccess = (state: ProfileStateType): ProfileStateType => ({
  ...state,
  confirmEmailSuccess: true,
  confirmEmailLoading: false,
});

const confirmEmailFail = (
  state: ProfileStateType,
  action: ProfileActionType,
): ProfileStateType => ({
  ...state,
  confirmEmailError: action.confirmEmailError,
  confirmEmailLoading: false,
});

const resetEmailVerificationStore = (
  state: ProfileStateType,
): ProfileStateType => ({
  ...state,
  sendEmailVerificationLoading: false,
  sendEmailVerificationSuccess: false,
  sendEmailVerificationError: null,
  confirmEmailLoading: false,
  confirmEmailSuccess: false,
  confirmEmailError: null,
});

const resetPaymentStore = (): ProfileStateType => ({
  ...initialState,
});

const logout = (): ProfileStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ProfileActionType) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_ROLE_START:
      return setSelectedRoleStart(state);
    case actionTypes.SET_SELECTED_ROLE_SUCCESS:
      return setSelectedRoleSuccess(state);
    case actionTypes.SET_SELECTED_ROLE_FAIL:
      return setSelectedRoleFail(state, action);
    case actionTypes.SEND_PHONE_VERIFICATION_CODE_START:
      return sendPhoneVerificationCodeStart(state);
    case actionTypes.SEND_PHONE_VERIFICATION_CODE_SUCCESS:
      return sendPhoneVerificationCodeSuccess(state);
    case actionTypes.SEND_PHONE_VERIFICATION_CODE_FAIL:
      return sendPhoneVerificationCodeFail(state, action);
    case actionTypes.CONFIRM_PHONE_NUMBER_START:
      return confirmPhoneNumberStart(state);
    case actionTypes.CONFIRM_PHONE_NUMBER_SUCCESS:
      return confirmPhoneNumberSuccess(state);
    case actionTypes.CONFIRM_PHONE_NUMBER_FAIL:
      return confirmPhoneNumberFail(state, action);
    case actionTypes.RESET_PHONE_VERIFICATION_STORE:
      return resetPhoneVerificationStore(state);
    case actionTypes.SEND_EMAIL_VERIFICATION_START:
      return sendEmailVerificationStart(state);
    case actionTypes.SEND_EMAIL_VERIFICATION_SUCCESS:
      return sendEmailVerificationSuccess(state);
    case actionTypes.SEND_EMAIL_VERIFICATION_FAIL:
      return sendEmailVerificationFail(state, action);
    case actionTypes.CONFIRM_EMAIL_START:
      return confirmEmailStart(state);
    case actionTypes.CONFIRM_EMAIL_SUCCESS:
      return confirmEmailSuccess(state);
    case actionTypes.CONFIRM_EMAIL_FAIL:
      return confirmEmailFail(state, action);
    case actionTypes.RESET_EMAIL_VERIFICATION_STORE:
      return resetEmailVerificationStore(state);
    case actionTypes.RESET_PROFILE_STORE:
      return resetPaymentStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
